import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

import Image from "./Image";

const Sunshades = ({ frontmatter }) => {
  return (
    <div className="container d-flex flex-wrap pt-5 pt-lg-6">
      <h3 className="col-12 pb-2">{frontmatter.header}</h3>
      <div className="d-flex justify-content-lg-start justify-content-center flex-column col-12 px-md-0 px-2 col-lg-6 px-0">
        <Carousel
          variant="dark"
          className="col-md-8 col-lg-12 align-self-center flex-md-shrink-1"
          indicators={false}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="lg" color="#212529" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" color="#212529" />}
        >
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="sennici/1.jpg"
              alt="Сенник"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="sennici/2.jpg"
              alt="Сенник"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="sennici/4.jpg"
              alt="Сенник"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
        </Carousel>
        <div className="row justify-content-center my-3">
          <Link className="btn btn-primary" to="/gallery/#sennici" activeClassName="active">
            Към Галерията
          </Link>
        </div>
      </div>
      <div className="col-lg-6">
        <div>
          <strong>Сенници от поликарбонат</strong>
          <br />
          Този тип сенници представлява метална конструкция покрита с поликарбонат. Тази комбинация
          е здрава и устойчива на всякакви атмосферни условия.
          <ul>
            <li>Различна дебелина, прозрачност и различен цвят на поликарбоната</li>
            <li>Различни цветове на металната конструкция</li>
          </ul>
        </div>
        <div>
          <strong>Тенти</strong>
          <br />
          Тентите, които предлагаме, представляват алуминиева конструкция с акрилен плат. Различни
          модели и цветове на плата.
        </div>
      </div>
    </div>
  );
};

Sunshades.propTypes = {
  frontmatter: PropTypes.object,
};

Sunshades.defaultProps = {
  frontmatter: {},
};

export default Sunshades;
